export function buildDateArray(givenDate) {
  const date = new Date(givenDate);
  const currentYear = new Date().getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month as mm (01-12)

  const result = [];

  for (let i = 0; i < 3; i++) {
    const year1 = currentYear + i;
    const year2 = year1 + 1;

    result.push({
      value: `${year1}`,
      label: `${month}/${year1} - ${month}/${year2}`
    });
  }

  return result;
}

export function buildListOfMonths(startDate) {
  // Hebrew month names indexed by month number (1-based: January=1)
  const hebrewMonths = [
    "ינואר",
    "פברואר",
    "מרץ",
    "אפריל",
    "מאי",
    "יוני",
    "יולי",
    "אוגוסט",
    "ספטמבר",
    "אוקטובר",
    "נובמבר",
    "דצמבר"
  ];

  let date = startDate instanceof Date ? new Date(startDate) : new Date();

  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  const result = [];

  for (let i = 0; i < 12; i++) {
    if (month > 12) {
      month = 1;
      year += 1;
    }

    const label = `${hebrewMonths[month - 1]} ${year}`;
    const value = `${month}/${year}`;

    result.push({ label, value });
    month++;
  }

  return result;
}

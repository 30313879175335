import React, {useState} from 'react';
import CustomInput from '../../components/CustomInput';
import Dropdown from "../Dropdown";
import {WORDS} from '../../dictionary/words'
import PhoneNumberInput from "../PhoneNumberInput";
import DateInput from "../DateInput";
import 'react-tailwindcss-datepicker';
import {buildDateArray, buildListOfMonths} from "./utils";
const DynamicForm = ({
                       isShowPhone = true,
                       isShowEmail = true,
                       onFormSubmit,
                       productName,
}) => {
  const LIST_OF_FIELDS = WORDS.PRODUCTS[productName]?.PRODUCT_FORM.LIST_OF_FIELDS ?? [];
  const rowNumbers = LIST_OF_FIELDS.reduce((final, {ROW}) => {
    final.push(ROW);
    return final;
  }, []).sort((a, b) => a - b);


  const SUBMIT_CAPTION = WORDS.PRODUCT_FORM.SUBMIT_CAPTION;

  const basicFormData = LIST_OF_FIELDS.reduce((acc, field) => {
    acc[field.ID] = ''; // Set each ID as a key and an empty string as the value
    return acc;
  }, {});

  console.log({basicFormData})

  const initialFormData = Object.assign({},
    basicFormData,
    { productName },
    // {
    // "firstName": "מיכאל",
    // "lastName": "קושניריוב",
    // "date_of_birth": "1979-11-08",
    // "gender": "male",
    // "phoneNumber": "0542403539",
    // "email": "michael.kushniriov@gmail.com",
    // "termsAndConditions": true,
    // },
    //
    // {productName: "PRODUCT_C"}
  );

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});
  const [isTermsAndConditionsChecked, setIsTermsAndConditionsChecked] = useState(false);
  const [isTermsAndConditionsOpened, setIsTermsAndConditionsOpened] = useState(false);

  const handleCheckboxChange = () => {
    setIsTermsAndConditionsChecked(!isTermsAndConditionsChecked);
    formData['termsAndConditions'] = !isTermsAndConditionsChecked;
    validate();
  };

  const handleTermsAndConditionsOpened = () => {
    setIsTermsAndConditionsOpened(true);
    validate();
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    validate(name);
  };

  const validate = (name) => {

    let formErrors = {};

    LIST_OF_FIELDS.forEach(field => {
      const { ID, PLACEHOLDER } = field;
      if (
        (ID === 'email' && isShowEmail) ||
        (ID === 'phoneNumber' && isShowPhone) ||
        (ID !== 'email' && ID !== 'phoneNumber')) {
        const value = formData[ID]?.trim?.() ?? formData[ID];

        console.log('validate', {name, ID, value})
        if (!value) {
          formErrors[ID] = `יש להזין ${PLACEHOLDER} `;
        }

        // if ((!isTermsAndConditionsOpened || !value) && ID === 'termsAndConditions') {
        //   formErrors[ID] = `${!isTermsAndConditionsOpened ? 'יש לקרוא ו' : ''}לאשר תקנון האתר. ${PLACEHOLDER} `;
        // }

      }
    });

    // console.log('------------------------------------')
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0 //&& isTermsAndConditionsOpened;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validate()) {
      onFormSubmit(formData);
    }
  };

  const getDropdownOptions = (field) => {
    if (!field.OPTIONS) {
      return null;
    }

    if (field.ID === 'years_period_first_year') {
      return buildDateArray(formData.date_of_birth);
    }
    if (field.ID === 'chosen_month_period') {
      return buildListOfMonths()
    }

    return field.OPTIONS;
  }
  const renderRow = (row) => {
    const fieldsInRow = LIST_OF_FIELDS.filter(field => field.ROW === row);

    const isDisabled = (field) => {
      if (!field.disabledWithoutID) {
        return false;
      }

      return !formData[field.disabledWithoutID];
    }

    return (
      <div className={`grid grid-cols-${fieldsInRow.length} gap-4 mb-4`} key={'row_' + row} dir={'rtl'}>
        {fieldsInRow.map((field) => {
          if (
            (field.ID === 'phoneNumber' && !isShowPhone) ||
            (field.ID === 'email' && !isShowEmail)) {
            return null;
          }

          return getDropdownOptions(field) ? (
            !isDisabled(field) &&
            <div key={field.ID}>
              <Dropdown label={field.PLACEHOLDER}
                        name={field.ID}
                        items={getDropdownOptions(field)}
                        onChange={handleInputChange}
                        disabled={isDisabled(field)}
              />
             {errors[field.ID] && <p className="text-red-500">{errors[field.ID]}</p>}
            </div>

          ) : (
            <div key={field.ID} className={'w-full'}>
              {
                field.ID === 'phoneNumber' &&
                <PhoneNumberInput label={field.PLACEHOLDER}
                                  name={field.ID}
                                  onPhoneNumberChange={handleInputChange}/>
              }
              {
                field.ID === 'date_of_birth' &&
                <DateInput label={field.PLACEHOLDER}
                           name={field.ID}
                           onDateChange={handleInputChange} />
              }
              {
                !['phoneNumber', 'date_of_birth', 'termsAndConditions'].includes(field.ID) &&
                <CustomInput
                  type={'text'}
                  name={field.ID}
                  value={formData[field.ID]}
                  placeholder={field.PLACEHOLDER}
                  onChange={handleInputChange}
                  onFocus={validate}
                  onInput={validate}
                  className={'w-full'}
                  required
                />
              }
              {
                field.ID === 'termsAndConditions' && (
                  <div className="flex items-center ">
                    <input
                      type="checkbox"
                      id="large-checkbox"
                      checked={isTermsAndConditionsChecked}
                      onChange={handleCheckboxChange}
                      className="hidden peer"
                    />
                    <div
                      className={`w-8 h-8 border-2 border-gray-300 rounded-lg flex items-center justify-center cursor-pointer transition duration-200 ${
                        isTermsAndConditionsChecked ? 'bg-tree87Green' : 'bg-white-500'
                      }`}
                      onClick={handleCheckboxChange}
                    >
                      {isTermsAndConditionsChecked && (
                        <svg
                          className="w-4 h-4 text-white"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      )}
                    </div>
                    <label htmlFor="large-checkbox" className="m-2 text-lg">
                      <a href={WORDS.CONTACT_US.DATA.LEFT.WEBSITE_REGULATIONS_URL}
                         onClick={handleTermsAndConditionsOpened}
                         target="_blank"
                         className="text-blue-600 underline hover:text-blue-800"
                         rel="noopener noreferrer">
                        {field.PLACEHOLDER}
                      </a>
                    </label>

                  </div>
                )
              }
              {errors[field.ID] && <p key={field.ID + '_100'} className="text-red-500">{errors[field.ID]}</p>}
            </div>
          );
        }).filter(x => !!x)}
      </div>
    );
  };

  return (
    <form className="space-y-6" onSubmit={handleSubmit} noValidate>
      {
        rowNumbers.map(row => renderRow(row))
      }
      <button
        type="submit"
        className="relative group w-full border-4 border-tree87Green
        bg-white py-2 px-4 rounded-full overflow-hidden hover:opacity-90
        transition duration-800 hover:text-tree87Green-800"
      >
        <div>
          {SUBMIT_CAPTION}
          <div
            className="absolute bg-tree87Green inset-0 bg-gradient-to-r from-transparent via-white to-transparent opacity-0 group-hover:opacity-60 group-hover:animate-sparkle"></div>
        </div>
      </button>

    </form>
  );
};

export default DynamicForm;

import React from 'react';
import tree87greenLogo from "../../assets/images/tree87-logo-384.png";
import {useNavigate} from "react-router-dom";

const ProductCard = ({ caption, link, imageUrl, productName, isDisabled }) => {
  const navigate = useNavigate();

  const onClick = () => {
    if (!isDisabled) {
      return navigate(link)
    }
  }

  const disabledClasses = isDisabled ? `opacity-25 opacity-50 cursor-not-allowed` : ' hover:cursor-pointer ';
  const mainClass = ` relative overflow-hidden border-2 border-black rounded-t-full rounded-b-none w-80 text-center p-2 m-4 ${disabledClasses}`;
  return (
    <div className={mainClass}
         onClick={onClick}>
      {
        isDisabled &&
        <div
          className="absolute inset-0 flex justify-center font-bold text-5xl bg-transparent text-red-700 mt-[125px]">
          מגיע בקרוב
        </div>
      }
      <div className="rounded-t-full overflow-hidden">
        <img src={imageUrl} alt={productName} className="w-full"/>
      </div>
      <div className="py-5">
        <h3 className="text-2xl text-black font-semibold mb-3">{productName}</h3>
        <img src={tree87greenLogo} alt="tree87 logo" className="mx-auto mb-4 m-0 color-tree87Green"/>
      </div>
    </div>
  );
};

export default ProductCard;
